<template>
    <form ref="form" method="post" action="https://payu.nicepay.co.kr/main.do" accept-charset="euc-kr">
        <input type="text" name="MID" :value="mid" />
        <input type="text" name="MallUserID" :value="buyerUsername" />
        <input type="text" name="UserKey" :value="userKey" v-if="userKey" />
        <input type="text" name="AuthType" value="2" v-if="!userKey" />
        <input type="text" name="BuyerName" :value="buyerName" />
        <input type="text" name="BuyerTel" :value="buyerPhone" />
        <input type="text" name="BuyerEmail" :value="buyerEmail" />
        <input type="text" name="LogoImgUrl" :value="logoUrl" />
        <!-- <input type="text" name="GuideTitle" value="Title" /> -->
        <!-- <input type="text" name="GuideMsg" value="Msg" /> -->
        <input type="text" name="Moid" :value="orderNo" />

        <input type="text" name="EdiDate" />
        <input type="text" name="Amt" :value="paymentAmount" />
        <input type="text" name="GoodsName" :value="orderName" />
        <input type="text" name="ReturnUrl" :value="returnUrl" />

        <input type="text" name="RcvName" :value="receiverName" />
        <input type="text" name="RcvTel" :value="receiverPhone" />
        <input type="text" name="RcvPostNo" :value="receiverPostcode" />
        <input type="text" name="RcvAddr1" :value="receiverAddress1" />
        <input type="text" name="RcvAddr2" :value="receiverAddress2" />

        <input type="text" name="SignData" />
        <input type="text" name="SkinType" value="dark" />
        <input type="text" name="CharSet" value="euc-kr" />
    </form>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";

export default {
    data() {
        return {
            mid: null,
            userKey: this.$route.query.userKey, //                      사용자키
            buyerUsername: this.$route.query.buyerUsername, //          구매자 아이디

            orderNo: this.$route.query.orderNo, //                      제품 주문번호
            orderName: this.$route.query.orderName, //                  결제제품명
            paymentAmount: +this.$route.query.paymentAmount, //         제품 금액

            buyerName: this.$route.query.buyerName, //                  구매자명
            buyerPhone: this.$route.query.buyerPhone, //                구매자 전화번호
            buyerEmail: this.$route.query.buyerEmail, //                구매자 이메일

            receiverName: this.$route.query.receiverName, //            수취인 이름
            receiverPhone: this.$route.query.receiverPhone, //          수취인 연락처
            receiverPostcode: this.$route.query.receiverPostcode, //    배송지 우편번호
            receiverAddress1: this.$route.query.receiverAddress1, //    배송지 주소1
            receiverAddress2: this.$route.query.receiverAddress2, //    배송지 주소2

            hashString: null, //        위변조 구분 문자열
        };
    },
    computed: {
        ...mapState(["user"]),
        baseUrl() {
            let { protocol, host } = window.location;
            protocol = protocol.replace(/[^a-z]/g, "");
            return `${protocol}://${host}`;
        },
        logoUrl() {
            return `${this.baseUrl}/images/logo.svg`;
        },
        returnUrl() {
            return `${this.baseUrl}/api/plugins/nice/payu/response`;
        },
    },
    async mounted() {
        window.nicepayuComplete = (payload) => {
            window?.parent?.nicepayuComplete(payload);
            window?.opener?.nicepayuComplete(payload);
        };

        let { mid } = await api.plugins.nice.payu.config.get();

        this.mid = mid;
        this.submit();
    },
    methods: {
        async submit() {
            let { orderNo } = this.$data;
            let { ediDate, signData, userKey } = await api.plugins.nice.payu.generate({ orderNo });
            if (userKey) this.userKey = userKey;

            this.$nextTick((_) => {
                let form = this.$refs.form;

                form.EdiDate.value = ediDate;
                form.SignData.value = signData;

                if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1) {
                    // 모바일 환경일때 처리
                } else {
                    // PC 환경일때 처리
                    let popup = window.open("about:blank", "nice-payu", `width=420,height=671,status=no,scrollbars=no,resizable=no,left=${Math.round(screen.width / 2) - 210},top=${Math.round(screen.height / 2) - 335}`);

                    if (!popup) {
                        alert("팝업이 차단되어 결제를 진행할 수 없습니다.");
                        return false;
                    }

                    let interval = setInterval(() => {
                        if (popup.closed) {
                            clearInterval(interval);
                            window?.parent?.nicepayuComplete("closed");
                        }
                    }, 1000);

                    form.target = "nice-payu";
                }

                form.method = "post";
                form.submit();
            });
        },
    },
};
</script>
